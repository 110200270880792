import classnames from "classnames";
import { FC, HTMLAttributes } from "react";
import Chevron, { ChevronDirection } from "src/components/ui/Chevron/Chevron";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  direction?: ChevronDirection;
}

const BorderedChevron: FC<IProps> = props => {
  const { className, direction } = props;

  return (
    <div className={classnames(className, "ml-auto flex size-8 items-center justify-center rounded-full bg-white")}>
      <Chevron size="sm" colour="gray" direction={direction} />
    </div>
  );
};

export default BorderedChevron;
