import { hasCookie } from "cookies-next";
import { useRouter } from "next/router";
import { FC, createContext, PropsWithChildren, useEffect } from "react";
import { useQuery } from "react-query";
import { queryKey as getSessionDataKey, getSessionData } from "src/api/auth/getSessionData";
import { useAppDispatch } from "src/hooks/useRedux";
import { paths } from "src/interfaces/api";
import { SESSION_COOKIE_KEY } from "src/constants/global";
import { setSessionUserType } from "src/redux/modules/session";

interface IProps {}

type TSessionData = paths["/users/me"]["get"]["responses"]["200"]["content"]["application/json"];

export const SessionContext = createContext<TSessionData | undefined>(undefined);

const SessionProvider: FC<PropsWithChildren<IProps>> = props => {
  const { children } = props;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { data, isError, status } = useQuery(getSessionDataKey, async () => (await getSessionData({})).data, {
    enabled: hasCookie(SESSION_COOKIE_KEY),
    onSuccess: ({ data }) => {
      // @ts-expect-error TODO Explain error API docs states "type" is just a string
      dispatch(setSessionUserType(data?.attributes?.type || "unknown"));
    }
  });

  useEffect(() => {
    if ((isError || status === "idle") && router.route !== "/login") {
      router.replace("/login");
    }
  }, [isError, router, status]);

  return <SessionContext.Provider value={data}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
