var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ceef76237299c1751514749c04a63304c65ac5bc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
const version = require("./package.json").version;

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;
const sampleRate = SENTRY_ENVIRONMENT === "production" ? 1.0 : 0.6;

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: SENTRY_DSN || "https://6f36223b7e0148b0baa5648ad4959c6b@o163691.ingest.sentry.io/6567230",
    tracesSampleRate: sampleRate,
    environment: SENTRY_ENVIRONMENT,
    release: version
  });
}
