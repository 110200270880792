import { FC, HTMLAttributes } from "react";
import classnames from "classnames";

export interface IProps extends HTMLAttributes<HTMLElement> {
  stageClassName?: string;
  numOfStages: number;
  activeStage: number;
}

const PaginationDots: FC<IProps> = props => {
  const { numOfStages, activeStage, className, stageClassName, ...rest } = props;

  return numOfStages > 1 ? (
    <nav aria-label="pagination" className={classnames(className)} {...rest}>
      <ul className="flex space-x-2">
        {[...Array(numOfStages)].map((...[, id]) => (
          <li key={id}>
            <span
              className={classnames(
                stageClassName,
                "block size-2.5 rounded-full",
                id === activeStage ? "bg-primary-purple" : "bg-secondary-grey"
              )}
              aria-current={id === activeStage ? "page" : undefined}
            />
          </li>
        ))}
      </ul>
    </nav>
  ) : null;
};

export default PaginationDots;
