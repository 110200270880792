import { useRouter } from "next/router";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import Image from "next/legacy/image";
import SplashScreen from "src/components/SplashScreen";
import useHasDomLoaded from "src/hooks/useHasDomLoaded";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import headwayLogo from "public/headway-logo-white.svg";
import { minDesktop } from "src/constants/windowDimensions";

interface IProps {
  hasSplashScreen?: boolean;
}

const MobileWrapper: FC<PropsWithChildren<IProps>> = props => {
  const { hasSplashScreen = true, children } = props;
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const router = useRouter();
  // The server will pre-render the component with windowWidth being 0
  // Must wait one render until the desktop version should display
  // Avoiding: "Hydration failed because the initial UI does not match what was rendered on the server."
  const hasDomLoaded = useHasDomLoaded();
  const [isShowingSplashScreen, setIsShowingSplashScreen] = useState(true);
  const [mobileContainerRef, setMobileContainerRef] = useState<HTMLDivElement | null>(null);

  // This will be set somewhere else soon
  // Based on API calls
  useEffect(() => {
    setTimeout(() => {
      setIsShowingSplashScreen(false);
    }, 1000);
  }, [hasDomLoaded]);

  // On Route changes, scroll the mobile container to the top
  useEffect(() => {
    const handleRouteChange = () => {
      mobileContainerRef?.scrollTo(0, 0);
    };

    if (mobileContainerRef) {
      router.events.on("routeChangeComplete", handleRouteChange);

      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [mobileContainerRef, router]);

  useEffect(() => {
    const setVerticalHeight = () => {
      let vh = window.innerHeight * 0.01;
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty("--vw", `${vw}px`);

      if (window.innerWidth >= minDesktop && mobileContainerRef) {
        vh = mobileContainerRef.clientHeight * 0.01;
        vw = mobileContainerRef.clientWidth * 0.01;
        mobileContainerRef.style.setProperty("--vh", `${vh}px`);
        mobileContainerRef.style.setProperty("--vw", `${vw}px`);
      }
    };

    if (typeof window !== "undefined") {
      setVerticalHeight();
      window.addEventListener("resize", setVerticalHeight);
      return () => window.removeEventListener("resize", setVerticalHeight);
    }
  }, [mobileContainerRef]);

  return windowWidth >= minDesktop && windowHeight >= minDesktop && hasDomLoaded ? (
    <div className="relative flex h-screen w-screen scroll-p-6 items-center justify-around overflow-auto bg-primary-purple">
      <div className="flex h-full items-center">
        <div className="left-[20px] text-center xl:left-[128px]">
          <Image height={146} width={150} quality={100} alt="Headway Logo" src={headwayLogo} />

          <span className="mt-[22px] block font-myriad text-h1 font-bold text-white">Headway Prototype</span>
        </div>
      </div>
      <div className="flex h-full ">
        <div className="relative m-auto h-[830px] w-[418px] bg-[url('/mobile-wrapper.png')] bg-cover pb-[32px] pt-[70px]">
          {/* "translate-x-0" - If an ancestor has a transform property set to
           * something other than none, then that ancestor behaves as the
           * containing block for fixed position elements - we need this for Modals
           * https://developer.mozilla.org/en-US/docs/Web/CSS/position#fixed */}
          <div className="mx-auto h-full w-[352px] translate-x-0 overflow-hidden rounded-b-[30px]">
            <div ref={setMobileContainerRef} className="relative h-full overflow-auto bg-white">
              {children}

              {hasSplashScreen && (
                <SplashScreen mobileContainerRef={mobileContainerRef} isShowing={isShowingSplashScreen} />
              )}
            </div>

            {/* Workaround for https://github.com/tailwindlabs/headlessui/discussions/666 */}
            <div id="headlessui-portal-root">
              {/* It needs at least one child, so that HeadlessUI doesn't remove this portal root workaround
              ( https://github.com/tailwindlabs/headlessui/blob/main/packages/@headlessui-react/src/components/portal/portal.tsx#L84 ) */}
              <div />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[10%]" />
    </div>
  ) : (
    <>
      {children}
      <SplashScreen isShowing={isShowingSplashScreen} />
    </>
  );
};

export default MobileWrapper;
