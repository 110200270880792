import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LinkProps } from "next/link";

export interface IUnlockRewardState {
  rewardedChildId?: number;
  rewardedBannerId?: number;
  returnLinkProps?: LinkProps;
}

const initialState: IUnlockRewardState = {};

export const unlockRewardSlice = createSlice({
  name: "unlockReward",
  initialState,
  reducers: {
    resetUnlockRewardState: () => {
      return initialState;
    },
    setUnlockReward: (state, action: PayloadAction<Required<IUnlockRewardState>>) => {
      state.rewardedChildId = action.payload.rewardedChildId;
      state.rewardedBannerId = action.payload.rewardedBannerId;
      // @ts-expect-error TODO Explain error ToDo
      state.returnLinkProps = action.payload.returnLinkProps;
    }
  }
});

export const { resetUnlockRewardState, setUnlockReward } = unlockRewardSlice.actions;

export default unlockRewardSlice.reducer;
